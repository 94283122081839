import httpService from "@/services/HttpService";
import moment from "moment";
// import store from "../store";

export default {
  async getVerificationCode(payload) {
    try {
      return await httpService.post("/login", payload); // todo рассмотреть обработку ошибок в другом месте
    } catch (error) {
      console.log(error);
    }
  },
  async login(credentials) {
    try {
      return await httpService.post("/login/verify", credentials); // todo рассмотреть обработку ошибок в другом месте
    } catch (error) {
      console.log("ApiService.login handler", error.response);
    }
  },

  async fetchUserBindings() {
    try {
      return await httpService.get("/accounts");
    } catch (error) {
      console.log("ApiService.fetchUserBindings handler", error.response);
    }
  },

  async fetchUserCards() {
    try {
      return await httpService.get("cards");
    } catch (error) {
      console.log("ApiService.fetchUserCards handler", error.response);
    }
  },

  async deleteUserCard(cardId) {
    await httpService.delete(`cards/${cardId}`);
  },

  async checkLsPrepare(ls) {
    return await httpService.post("accounts/verificationPrepare", {
      number: ls,
    });
  },

  async checkLs(ls, uk) {
    return await httpService.post("accounts/verification", {
      number: ls,
      uk: uk,
    });
  },

  async bindLs(payload) {
    return await httpService.post("accounts/verify", payload);
  },

  async deleteLsBinding(accountId) {
    await httpService.delete(`accounts/${accountId}`);
  },

  async fetchAccountCharges(accountId) {
    return await httpService.get(`accounts/${accountId}/charges`);
  },

  async fetchAccountBalance(accountId) {
    return await httpService.get(`accounts/${accountId}/balance`);
  },

  async fetchAccountPayments(accountId) {
    return await httpService.get(`accounts/${accountId}/payments`);
  },

  async fetchAccountCounters(accountId) {
    return await httpService.get(`accounts/${accountId}/meashures`);
  },

  async fetchNoAuthCounters(account) {
    return await httpService.get(`accountsData/${account}/meashures`);
  },

  async fetchAccountIndicationsHistory(accountId) {
    return await httpService.get(`accounts/${accountId}/indications`);
  },

  async sendIndications(accountId, payload) {
    return await httpService.post(`accounts/${accountId}/indications`, payload);
  },

  async sendNoAuthIndications(account, payload) {
    return await httpService.post(
      `accountsData/${account}/indications`,
      payload
    );
  },

  async sendAppeal(accountId, payload) {
    return await httpService.post(`accounts/${accountId}/appeals`, payload);
  },

  async downloadReceipt(accountId) {
    return await httpService.get(`accounts/${accountId}/epd`, {
      responseType: "blob",
    });
  },

  async downloadReceiptByDate(accountId, date) {
    date = moment(String(date)).format("DD.MM.YYYY");
    return await httpService.get(`accounts/${accountId}/epd/${date}`, {
      responseType: "blob",
    });
  },

  async getAppealsTypes() {
    return await httpService.get(`accounts/appealsTypes`);
  },

  async getPaymentLink(
    account,
    sum,
    type,
    email,
    sbp,
    source,
    debt,
    gp,
    recurrent,
    dayRecurrent,
    sumRecurrent,
    endRecurrent,
    selectedCardId
  ) {
    return await httpService.get(
      `accountsData/${account}/payment?amount=${sum}&type=${type}&email=${email}&sbp=${sbp}&source=${source}&debt=${debt}&gp=${gp}` +
        (recurrent
          ? `&dayRecurrent=${dayRecurrent}&sumRecurrent=${sumRecurrent}&endRecurrent=${endRecurrent}`
          : ``) +
        (selectedCardId ? `&card=${selectedCardId}` : ``)
    );
  },

  async getPaymentNoAuthLink(account, sum, email, sbp, debt, gp) {
    return await httpService.get(
      `accountsData/${account}/payment?amount=${sum}&type=epd&email=${email}&sbp=${sbp}&debt=${debt}&gp=${gp}`
    );
  },

  async checkIfAccountExists(account) {
    return httpService.get(`accountsData/${account}/exists`);
  },

  /**
   * Автоплатежи
   */
  async fetchAccountRecurrents(accountId) {
    return await httpService.get(`accounts/${accountId}/recurrents`);
  },

  async deleteAccountRecurrent(accountId, recurrentId) {
    await httpService.delete(`accounts/${accountId}/recurrents/${recurrentId}`);
  },

  /**
   * Госпошлина
   */
  async fetchAccountGP(accountId) {
    return await httpService.get(`accounts/${accountId}/gp`);
  },

  /**
   * Рассрочка
   */
  async fetchAccountDebt(accountId) {
    return await httpService.get(`accounts/${accountId}/debt`);
  },
};
