<template>
  <v-skeleton-loader
    height="300px"
    v-if="needLoadingIndicator"
    type="image"
    v-bind="$attrs"
  ></v-skeleton-loader>
  <div v-else>
    <simple-table
      :useCustomRows="true"
      :headers="headers"
      :rows="rows"
      :showFooter="false"
    >
      <template v-slot:table-row="{ item }">
        <tr class="custom_tr" @click="openDialog(item.month)">
          <td v-for="(cell, key) in item" :key="key">
            {{ getCellContent(cell, key) }}
            <v-icon
              @click.stop="downloadReceiptByDate(item['month'])"
              v-if="key === 'download_epd'"
              >mdi-download</v-icon
            >
          </td>
        </tr>
      </template>
    </simple-table>
    <simple-dialog :is-open="isDialogOpen" @close-dialog="closeDialog">
      <template v-slot:dialog-content>
        <simple-card>
          <template v-slot:title-left
            >Расшифровка начислений за {{ chargesDate }}</template
          >
          <template v-slot:title-right>
            <v-btn @click="closeDialog" icon>
              <v-icon>mdi-close</v-icon>
            </v-btn>
          </template>
          <template v-slot:content>
            <accruals-details
              :rows="detailsRows"
              activator=".custom_tr"
            ></accruals-details>
          </template>
          <template v-slot:footer>
            <div class="d-flex justify-end w-full">
              <v-btn color="primary" @click="closeDialog">Закрыть</v-btn>
            </div>
          </template>
        </simple-card>
      </template>
    </simple-dialog>
  </div>
</template>

<script>
import SimpleTable from "@/components/tables/SimpleTable";
import SimpleDialog from "@/components/dialogs/SimpleDialog";
import AccrualsDetails from "@/views/accruals/components/AccrualsDetails";
import SimpleCard from "@/components/cards/SimpleCard";
import AccrualsService from "../../../services/AccrualsService";
import { getAccrualDate } from "@/shared/helpers/date";
import { mapGetters } from "vuex";
import loadingIndicator from "../../../shared/mixins/loadingIndicator";

export default {
  name: "AccrualsHistory",
  components: { AccrualsDetails, SimpleTable, SimpleDialog, SimpleCard },
  mixins: [loadingIndicator],
  props: {
    rows: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      isDialogOpen: false,
      headers: AccrualsService.historyHeaders,
      selectedChargeDate: null,
      loadingFlag: "get_charges",
      downloadReceiptByDate: AccrualsService.downloadReceiptByDate,
    };
  },
  computed: {
    ...mapGetters("account", ["chargesByService"]),
    detailsRows() {
      if (this.selectedChargeDate)
        return AccrualsService.getDetailsRows(
          this.chargesByService(this.selectedChargeDate)
        );
      else return [];
    },
    chargesDate() {
      return getAccrualDate(this.selectedChargeDate);
    },
  },
  methods: {
    getCellContent(cell, key) {
      if (key === "month") return getAccrualDate(cell);
      else return cell;
    },
    openDialog(date) {
      this.isDialogOpen = true;
      this.selectedChargeDate = date;
    },
    closeDialog() {
      this.isDialogOpen = false;
      this.selectedChargeDate = null;
    },
  },
};
</script>

<style scoped>
.custom_tr {
  cursor: pointer;
}
</style>
