import StorageService from "../../services/StorageService";

export default {
  namespaced: true,
  state: () => ({
    miniSideBar: false,
    isMobile: false,
    isDrawerOpen: false,
    loading: false,
    update: StorageService.getItem("update"),
    loadingIdentifiers: [],
    alert: {
      shown: false,
      color: "",
      text: "",
    },
    modal: {
      shown: false,
      color: "",
      title: "",
      text: "",
    },
  }),
  mutations: {
    setIsMobile(state, payload) {
      state.isMobile = payload;
    },
    toggleDrawer(state) {
      state.isDrawerOpen = !state.isDrawerOpen;
    },
    setLoading(state, payload) {
      state.loading = payload;
    },
    setAlert(state, payload) {
      state.alert = payload;
    },
    setModal(state, payload) {
      state.modal = payload;
    },
    setLoadingIdentifier(state, payload) {
      state.loadingIdentifiers.push(payload);
    },
    unsetLoadingIdentifier(state, payload) {
      state.loadingIdentifiers = state.loadingIdentifiers.filter(
        (item) => item !== payload
      );
    },
    setUpdate(state, payload) {
      state.update = payload;
    },
  },
  actions: {
    setUpdate({ commit }, payload) {
      commit("setUpdate", payload);
      StorageService.setItem("update", payload);
    },
  },
  getters: {},
};
