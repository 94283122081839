<template>
  <v-data-table
    dense
    :headers="headers"
    :items="rows"
    v-bind="$attrs"
    class="elevation-1"
    :hide-default-footer="!showFooter"
    no-data-text="Нет данных для отображения"
    no-results-text="Нет данных для отображения"
    :header-props="{
      'sort-by-text': 'Сортировать по',
    }"
    :footer-props="{
      'items-per-page-all-text': 'Все',
      'items-per-page-text': 'Строк на странице',
      'page-text': `Всего записей: ${rows.length}`,
    }"
    :custom-sort="customSort"
  >
    <template v-if="useCustomRows" v-slot:item="{ item }">
      <slot name="table-row" :item="item"></slot>
    </template>
    <template v-slot:footer>
      <slot name="table-footer"></slot>
    </template>
  </v-data-table>
</template>

<script>
// для кастомного вывода строк через слоты, нужно передавать в компонент
// входящее свойство :useCustomRows="true", если оно не передано,
// то осуществляется стандартный вывод строк
export default {
  name: "SimpleTable",
  props: {
    headers: Array,
    rows: Array,
    showFooter: {
      type: Boolean,
      default: true,
    },
    useCustomRows: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    customSort: function (items, index, isDesc) {
      items.sort((a, b) => {
        if (index[0] === "date") {
          const timestampA = Date.parse(
            a[index].split(".").reverse().join(".")
          );
          const timestampB = Date.parse(
            b[index].split(".").reverse().join(".")
          );

          if (!isNaN(timestampA) && !isNaN(timestampB)) {
            if (!isDesc[0]) {
              return timestampB - timestampA;
            } else {
              return timestampA - timestampB;
            }
          } else {
            return 0;
          }
        } else {
          if (typeof a[index] === "string" && typeof b[index] === "string") {
            if (!isDesc[0]) {
              return a[index]
                .toLowerCase()
                .localeCompare(b[index].toLowerCase());
            } else {
              return b[index]
                .toLowerCase()
                .localeCompare(a[index].toLowerCase());
            }
          } else if (
            typeof a[index] === "number" &&
            typeof b[index] === "number"
          ) {
            if (!isDesc[0]) {
              return a[index] - b[index];
            } else {
              return b[index] - a[index];
            }
          } else {
            return 0;
          }
        }
      });
      return items;
    },
  },
};
</script>

<style scoped></style>
