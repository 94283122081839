<template>
  <v-dialog :value="isOpen" v-bind="$attrs" @input="$emit('close-dialog')">
    <slot name="dialog-content"></slot>
  </v-dialog>
</template>

<script>
export default {
  name: "SimpleDialog",
  props: {
    isOpen: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      dialog: this.isOpen,
    };
  },
};
</script>

<style scoped></style>
