<template>
  <simple-dialog persistent :is-open="modal.shown" class="result-dialog">
    <template v-slot:dialog-content>
      <simple-card>
        <template v-slot:title-left>
          <span>{{ modal.title }}</span>
        </template>
        <template v-slot:content>
          {{ modal.text }}
        </template>
        <template v-slot:footer>
          <div class="d-flex justify-end w-full">
            <v-btn @click="closeModal" color="primary" small> ОК </v-btn>
          </div>
        </template>
      </simple-card>
    </template>
  </simple-dialog>
</template>

<script>
import SimpleDialog from "./SimpleDialog";
import { mapState } from "vuex";
import SimpleCard from "../cards/SimpleCard";
export default {
  name: "ResultDialog",
  components: { SimpleCard, SimpleDialog },
  computed: {
    ...mapState("app", ["modal"]),
  },
  methods: {
    closeModal() {
      this.$store.commit("app/setModal", {
        shown: false,
        color: "",
        title: "",
        text: "",
      });
    },
  },
};
</script>
