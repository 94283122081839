<template>
  <v-select
    class="simple-select"
    v-bind="$attrs"
    @change="onItemChange"
    full-width
  ></v-select>
</template>

<script>
export default {
  name: "SimpleSelect",
  methods: {
    onItemChange(item) {
      this.$emit("item-changed", item);
    },
  },
};
</script>

<style scoped>
.v-select {
  /*max-width: 150px;*/
}
</style>
