var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-data-table',_vm._b({staticClass:"elevation-1",attrs:{"dense":"","headers":_vm.headers,"items":_vm.rows,"hide-default-footer":!_vm.showFooter,"no-data-text":"Нет данных для отображения","no-results-text":"Нет данных для отображения","header-props":{
    'sort-by-text': 'Сортировать по',
  },"footer-props":{
    'items-per-page-all-text': 'Все',
    'items-per-page-text': 'Строк на странице',
    'page-text': ("Всего записей: " + (_vm.rows.length)),
  },"custom-sort":_vm.customSort},scopedSlots:_vm._u([(_vm.useCustomRows)?{key:"item",fn:function(ref){
  var item = ref.item;
return [_vm._t("table-row",null,{"item":item})]}}:null,{key:"footer",fn:function(){return [_vm._t("table-footer")]},proxy:true}],null,true)},'v-data-table',_vm.$attrs,false))}
var staticRenderFns = []

export { render, staticRenderFns }