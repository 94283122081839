import ApiService from "./ApiService";
import store from "../store";
import AppService from "./AppService";

export default {
  rulesMark: "<v-icon>mdi-checkbox-blank-circle</v-icon>",

  sendingRules: [
    "<span>По <strong>ИПУ ЭЭ, ГВС и ХВС</strong> текущие показания необходимо вносить целым числом <strong>ДО</strong> запятой.</span>",
    "<span>По <strong>ИПУ тепла</strong> текущие показания надо передавать <strong>С ЦИФРАМИ ПОСЛЕ</strong> точки. Подробная инструкция по ссылке.</span>",
    '<span>Если вы не видите счётчика или информация по прибору учёта отражена неверно, отправьте письмо на электронный адрес <a href="mailto:info@nedolzhnik.ru">info@nedolzhnik.ru</a> с описанием проблемы.</span>',
  ],

  historyHeaders: [
    {
      text: "Дата",
      value: "date",
    },
    {
      text: "Счетчик",
      value: "counter",
    },
    {
      text: "Показание",
      value: "value",
    },
    {
      text: "Статус",
      // width: "50px",
      value: "result",
    },
  ],

  getIndicationsDataByCounter(indications) {
    return indications.map((counterArr) => {
      let counter = null;

      for (let i = 0; i < counterArr.length; i++) {
        if (!counter) {
          counter = store.getters["account/getCounterById"](counterArr[i].id);
        } else {
          break;
        }
      }

      counterArr.sort((a, b) => {
        if (a.last_indication?.date && b.last_indication?.date) {
          return (
            Date.parse(b.last_indication?.date) -
            Date.parse(a.last_indication?.date)
          );
        } else {
          return 0;
        }
      });

      return {
        rows: counterArr.map((indication) => ({
          date: indication?.last_indication?.date,
          counter: indication?.service?.name,
          value: indication?.last_indication?.epd,
          result: indication?.result,
        })),
        name: counter ? counter.name : null,
        img: counter ? process.env.VUE_APP_IMAGE_HOST + counter.img : null,
      };
    });
  },

  getColorByCounter(counterName) {
    counterName = counterName.toLowerCase();
    if (counterName.indexOf("холод")) return "#1e8dff";
    else if (counterName.indexOf("горяч")) return "#FF4C51";
    else if (counterName.indexOf("электр")) return "#FFB400";
    else return "#8A8D93";
  },

  getCounterCardStyle() {
    return {
      border: `2px solid #8A8D93`,
    };
  },

  isSendAvailableToday(counter) {
    const today = new Date().getDate();
    const availableFrom = counter.receive_period?.first_day;
    const availableTo = counter.receive_period?.last_day;
    return today >= availableFrom && today <= availableTo;
  },

  async getCounters() {
    try {
      const accountId = store.getters["user/activeBindingId"];
      const counters = await ApiService.fetchAccountCounters(accountId);
      await store.dispatch("account/setCounters", counters);
      AppService.unsetLoading("get_measures");
    } catch (e) {
      console.log(e);
    }
  },

  async getNoAuthCounters(ls) {
    try {
      const counters = await ApiService.fetchNoAuthCounters(ls);
      AppService.unsetLoading("get_measures");
      return counters;
    } catch (e) {
      store.commit("app/setAlert", {
        shown: true,
        text: "Счетчики не найдены",
        color: "error",
      });
      return [];
    }
  },

  async getIndicationsHistory() {
    try {
      const accountId = store.getters["user/activeBindingId"];
      const indications = await ApiService.fetchAccountIndicationsHistory(
        accountId
      ); //
      await store.dispatch("account/setIndications", indications);
      AppService.unsetLoading("get_indications");
    } catch (e) {
      console.log(e);
    }
  },

  async sendIndications(counter) {
    try {
      const payload = {
        [counter.id]: [counter],
      };
      const accountId = store.getters["user/activeBindingId"];
      const result = await ApiService.sendIndications(accountId, payload);

      AppService.unsetLoading("post_indications");

      if (!Object.values(result)[0].error) {
        store.commit("app/setAlert", {
          shown: true,
          text: "Показания успешно переданы",
          color: "success",
        });

        this.getCounters();
        this.getIndicationsHistory();
      } else {
        store.commit("app/setAlert", {
          shown: true,
          text: Object.values(result)[0].error,
          color: "error",
        });
      }
    } catch (e) {
      store.commit("app/setAlert", {
        shown: true,
        text: "Ошибка передачи показаний",
        color: "error",
      });
      console.log(e);
    }
  },

  async sendNoAuthIndications(counter, account) {
    try {
      const payload = {
        [counter.id]: [counter],
      };

      const result = await ApiService.sendNoAuthIndications(account, payload);

      AppService.unsetLoading("post_indications");

      if (!Object.values(result)[0].error) {
        store.commit("app/setAlert", {
          shown: true,
          text: "Показания успешно переданы",
          color: "success",
        });
      } else {
        store.commit("app/setAlert", {
          shown: true,
          text: Object.values(result)[0].error,
          color: "error",
        });
      }
    } catch (e) {
      store.commit("app/setAlert", {
        shown: true,
        text: "Ошибка передачи показаний",
        color: "error",
      });
    }
  },
};
