<template>
  <a
    v-if="!ng || (ng && !isMobile)"
    href="https://xn--d1abeikdleg.xn--p1ai/"
    target="_blank"
  >
    <v-img
      v-if="ng"
      alt="Logo"
      class="shrink mr-2"
      width="256"
      contain
      src="../assets/images/logos/logo_ng.svg"
      transition="scale-transition"
      style="cursor: pointer; margin-left: -32px; margin-top: -32px; margin-bottom: -32px"
    />
    <v-img
      v-else
      alt="Logo"
      class="shrink mr-2"
      width="150"
      contain
      src="../assets/images/logos/logo.svg"
      transition="scale-transition"
      style="cursor: pointer"
    />
  </a>
</template>

<script>
import {mapState} from "vuex";

export default {
  name: "AppLogo",
  data() {
    return {
      ng: true,
    };
  },
  computed: {
    ...mapState("app", {
      isMobile: (state) => state.isMobile,
    }),
  },
};
</script>

<style scoped>
</style>
