<template>
  <a href="https://promoydom.ru/" target="_blank">
    <v-img
      alt="Мой дом"
      class="ml-2"
      height="80"
      width="80"
      contain
      src="../assets/images/logos/md.png"
      transition="scale-transition"
      style="cursor: pointer; margin-top: -20px !important; margin-bottom: -20px !important;"
    />
  </a>
</template>

<script>
export default {
  name: "AppLogoMD",
};
</script>

<style scoped></style>
