export default class FormRules {
  required(message = "Необходимо заполнить это поле") {
    return (v) => !!v || message;
  }

  min(length, message) {
    return (v) => (v && v.length >= length) || message;
  }

  sameAs(field, message) {
    return (v) => {
      //для работы правила в наследующем классе должен быть payload[field]
      return (this.payload && v === this.payload[field]) || message;
    };
  }

  validEmail(message = "Некорректный адрес электронной почты") {
    return (v) => /.+@.+\..+/.test(v) || !v || message;
  }

  validPhone(message = "Некорректный номер телефона") {
    return (v) =>
      /^\+?[78][-(]?\d{3}\)?-?\d{3}-?\d{2}-?\d{2}$/.test(v) || message;
  }

  validPaymentSum(message = "Введите сумму больше 0") {
    return (v) => (!isNaN(v) && +v > 0) || message;
  }

  validLogin(message = "Некорректный логин. Введите e-mail или телефон") {
    return (v) => /.+@.+\..+/.test(v) || /^\+?[78][-(]?\d{3}\)?-?\d{3}-?\d{2}-?\d{2}$/.test(v) || !v || message;
  }
}
