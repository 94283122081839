<template>
  <no-auth-form-layout class="counters-no-auth-form" :class="layoutClass">
    <template v-slot:image>
      <div class="images-container d-flex justify-center w-full">
        <div class="image counters-image">
          <!--<v-img-->
          <!--alt="Laptop"-->
          <!--    class="mt-10"-->
          <!--    src="../../assets/images/tablet.png"-->
          <!--    contain-->
          <!--    max-width="20%"-->
          <!--/>-->
        </div>
      </div>
    </template>
    <template v-slot:title>Передача показаний</template>
    <template v-slot:form>
      <simple-form :form-instance="form"></simple-form>
      <v-row v-if="showCounters" class="mt-4">
        <counter-card
          v-for="counter in form.counters"
          :key="counter.id"
          :counter="counter"
          cols="12"
          sm="6"
          :is-no-auth="true"
          :account="form.payload.account"
          @update-counters="updateCounters"
        ></counter-card>
      </v-row>
    </template>
    <template v-slot:additional-links>
      <div>
        <router-link :to="{ name: 'login' }">Авторизоваться</router-link>
      </div>
    </template>
  </no-auth-form-layout>
</template>

<script>
import SimpleForm from "@/components/forms/SimpleForm";
import CountersNoAuthForm from "@/forms/no_auth/CountersNoAuthForm";
import NoAuthFormLayout from "@/components/Layouts/forms/NoAuthFormLayout";
import CounterCard from "../../components/cards/CounterCard";

export default {
  name: "ResetPassword",
  components: { CounterCard, NoAuthFormLayout, SimpleForm },
  data() {
    return {
      form: new CountersNoAuthForm(),
    };
  },
  methods: {
    updateCounters(counters) {
      this.form.counters = counters;
    },
  },
  computed: {
    showCounters() {
      return this.form.counters && this.form.counters.length;
    },
    layoutClass() {
      return {
        "-with-counters": this.form.counters.length > 0,
      };
    },
  },
  mounted() {
    if (this.$route.query.account) {
      this.form.payload.account = this.$route.query.account;
    }
  },
};
</script>

<style lang="scss">
.counters-no-auth-form {
  &.-with-counters {
    > .auth-inner {
      width: 35rem;
    }
  }

  .images-container {
    height: 300px;

    .image {
      &.counters-image {
        width: 100%;
        background: url("../../assets/images/counters_no_auth.png") no-repeat
          center;
        background-size: 100%;
      }
    }
  }
}
</style>
