import store from "../store";
import StorageService from "./StorageService";
import router from "../router";
import ApiService from "@/services/ApiService";
import UserService from "./UserService";
import AppService from "./AppService";

export default {
  get token() {
    return StorageService.getItem("token");
  },
  get isLoggedIn() {
    return store.state.auth.isLoggedIn;
  },
  logoutUser() {
    UserService.setUserInfo({
      token: null,
      login: null,
    });
    store.dispatch("user/setBindings", null).then(() => {});
    store.dispatch("user/setActiveBinding", null).then(() => {});
    store.dispatch("account/setCharges", null).then(() => {});
    store.dispatch("user/setCards", null).then(() => {});
  },
  async loginUser(code, confirmPD) {
    try {
      const verificationPayload = StorageService.getItem("verificationPayload");
      const payload = { ...verificationPayload, code, confirmPD };
      const { token, login } = await ApiService.login(payload);
      AppService.unsetLoading("post_verify");

      await UserService.setUserInfo({ token, login });
      await this.unsetVerificationPayload();
      const route = await UserService.getRouteByBindings();

      router.push({ name: route }).then(() => {});
    } catch (error) {
      console.log("AuthService handler", error);
    }
  },
  async getVerificationCode(form) {
    try {
      const data = await ApiService.getVerificationCode({
        login: form.payload.contact,
        confirmPD: form.payload.confirmPD,
      });
      if (data === undefined) {
        return;
      }
      store.dispatch("auth/setVerificationPayload", data).then(() => {});
      store.commit("app/setAlert", {
        shown: true,
        text: data?.message,
        color: "success",
      });
      AppService.unsetLoading("post_login");
      form.setSendCodeState(data?.seconds || 0, data?.type || "");
    } catch (error) {
      console.log(error);
    }
  },

  async unsetVerificationPayload() {
    await store.dispatch("auth/setVerificationPayload", null);
  },
};
