import Vue from "vue";
import VueRouter from "vue-router";
import Login from "../views/auth/Login";
import NotFound from "@/views/errors/NotFound";
import { handleAuth } from "@/router/helpers";
import PayNoAuth from "@/views/no_auth/PayNoAuth";
import CountersNoAuth from "@/views/no_auth/CountersNoAuth";
import { handleBindings } from "./helpers";

Vue.use(VueRouter);

const routes = [
  {
    path: "/login",
    name: "login",
    component: Login,
  },
  {
    path: "/pay-no-auth",
    name: "pay-no-auth",
    component: PayNoAuth,
  },
  {
    path: "/counters-no-auth",
    name: "counters-no-auth",
    component: CountersNoAuth,
  },
  {
    path: "/",
    redirect: "home",
  },
  {
    path: "/home",
    name: "home",
    component: () => import("../views/main/Main"),
    meta: {
      requiresAuth: true,
      requiresBinding: true,
    },
  },
  {
    path: "/current-payments",
    name: "currentPayments",
    component: () => import("../views/current-payments/CurrentPayments"),
    meta: {
      requiresAuth: true,
      requiresBinding: true,
    },
  },
  {
    path: "/accruals",
    name: "accruals",
    component: () => import("../views/accruals/Accruals"),
    meta: {
      requiresAuth: true,
      requiresBinding: true,
    },
  },
  {
    path: "/counters",
    name: "counters",
    component: () => import("../views/counters/Counters"),
    meta: {
      requiresAuth: true,
      requiresBinding: true,
    },
  },
  {
    path: "/appeals",
    name: "appeals",
    component: () => import("../views/appeals/Appeals"),
    meta: {
      requiresAuth: true,
      requiresBinding: true,
    },
  },
  // {
  //   path: "/loyalty",
  //   name: "loyalty",
  //   component: () => import('../views/loyalty/Loyalty'),
  //   meta: {
  //     requiresAuth: true,
  //     requiresBinding: true
  //   }
  // },
  // {
  //   path: "/faq",
  //   name: "faq",
  //   component: () => import('../views/faq/FAQ'),
  //   meta: {
  //     requiresAuth: true,
  //     requiresBinding: false
  //   }
  // },
  {
    path: "/help",
    name: "help",
    component: () => import("../views/help/Help"),
    meta: {
      requiresAuth: true,
      requiresBinding: false,
    },
  },
  {
    path: "/contacts",
    name: "contacts",
    component: () => import("../views/contacts/Contacts"),
    meta: {
      requiresAuth: true,
      requiresBinding: false,
    },
  },
  // {
  //   path: "/news",
  //   name: "news",
  //   component: () => import('../views/news/News'),
  //   meta: {
  //     requiresAuth: true,
  //     requiresBinding: true
  //   }
  // },
  // {
  //   path: "/eds",
  //   name: "eds",
  //   component: () => import('../views/eds/Eds'),
  //   meta: {
  //     requiresAuth: true,
  //     requiresBinding: true
  //   }
  // },
  {
    path: "/payment",
    name: "payment",
    component: () => import("../views/payment/Payment"),
    meta: {
      requiresAuth: true,
      requiresBinding: true,
    },
  },
  {
    path: "/successful-payment-page",
    name: "successful-payment-page",
    component: () => import("../views/payment/SuccessfulPayment"),
    meta: {
      requiresAuth: false,
      requiresBinding: false,
      availableEverywhere: true,
    },
  },
  // {
  //   path: "/settings",
  //   name: "settings",
  //   component: () => import('../views/settings/Settings'),
  //   meta: {
  //     requiresAuth: true,
  //     requiresBinding: true
  //   }
  // },
  {
    path: "/bindings",
    name: "bindings",
    component: () => import("../views/bindings/Bindings"),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/*",
    component: NotFound,
  },
];

const router = new VueRouter({
  mode: "history",
  // base: process.env.BASE_URL,
  routes,
});

router.beforeEach(handleAuth);
router.beforeEach(handleBindings);

export default router;
