import StorageService from "@/services/StorageService";
import router from "./index";

const requiresAuth = (to) =>
  to.matched.some((record) => record.meta.requiresAuth);
const requiresBinding = (to) =>
  to.matched.some((record) => record.meta.requiresBinding);
const availableEverywhere = (to) =>
  to.matched.some((record) => record.meta.availableEverywhere);

export const handleAuth = (to, from, next) => {
  if (availableEverywhere(to)) {
    return next();
  }

  const isLoggedIn = StorageService.getItem("isLoggedIn");
  if (requiresAuth(to)) {
    // этот путь требует авторизации, проверяем залогинен ли
    // пользователь, и если нет, перенаправляем на страницу логина
    if (!isLoggedIn) {
      next({
        name: "login",
        // query: { redirect: to.fullPath }
      });
    } else {
      next();
    }
  } else {
    if (isLoggedIn) {
      next({ name: "home" });
    } else {
      next();
    }
  }
};

export const handleBindings = (to, from, next) => {
  const bindings = StorageService.getItem("bindings");

  if (requiresBinding(to)) {
    if (bindings?.length) {
      next();
    } else {
      next({
        name: "bindings",
      });
    }
  } else {
    next();
  }
};

export const goToRoute = (route) => {
  if (router.currentRoute.name !== route) router.push({ name: route });
};
