<template>
  <v-snackbar
    v-model="alert.shown"
    :color="alert.color"
    :top="true"
    :right="true"
  >
    {{ alert.text }}

    <template v-slot:action="{ attrs }">
      <v-btn
        color="pink"
        text
        v-bind="attrs"
        @click="closeAlert()"
        x-small
        icon
      >
        <v-icon>mdi-close</v-icon>
      </v-btn>
    </template>
  </v-snackbar>
</template>

<script>
import { mapState } from "vuex";

export default {
  name: "Alert",
  data() {
    return {
      timeout: 15000,
      timeoutLink: null,
    };
  },
  mounted() {
    setTimeout(() => {
      this.closeAlert();
    }, this.timeout);
  },
  computed: {
    ...mapState("app", ["alert"]),
  },
  methods: {
    closeAlert() {
      this.$store.commit("app/setAlert", { ...this.alert, shown: false });
    },
  },
};
</script>

<style scoped></style>
