import ApiService from "./ApiService";
import store from "../store";
import AppService from "./AppService";

export default {
  async checkLs(account) {
    return await ApiService.checkIfAccountExists(account);
  },

  getPaymentsSum(payments) {
    if (payments && payments.length) {
      const sum = payments.reduce((prev, current) => {
        return typeof prev === "object"
          ? prev.payed + current.payed
          : prev + current.payed;
      });

      return sum.toFixed(2);
    } else {
      return 0;
    }
  },

  async getPaymentLink(
    sum,
    type,
    email = "",
    sbp = false,
    source = "",
    debt = 0,
    gp = 0,
    recurrent = false,
    dayRecurrent = 0,
    sumRecurrent = 0,
    endRecurrent = "",
    selectedCardId = ""
  ) {
    const account = store.getters["user/activeBindingLs"];
    const link = ApiService.getPaymentLink(
      account,
      sum,
      type,
      email,
      sbp,
      source,
      debt,
      gp,
      recurrent,
      dayRecurrent,
      sumRecurrent,
      endRecurrent,
      selectedCardId
    );
    AppService.unsetLoading("get_payment");

    return link;
  },

  async getPaymentNoAuthLink(account, sum, email, sbp = false, debt = 0, gp = 0) {
    const link = await ApiService.getPaymentNoAuthLink(
      account,
      sum,
      email,
      sbp,
      debt,
      gp,
    );
    AppService.unsetLoading("get_payment");

    return link;
  },

  async getRecurrents(accountId) {
    try {
      const recurrents = await ApiService.fetchAccountRecurrents(accountId);
      await store.dispatch("account/setRecurrents", recurrents);
    } catch (e) {
      console.log(e);
    }
  },

  async getGP(accountId) {
    try {
      const gp = await ApiService.fetchAccountGP(accountId);
      await store.dispatch("account/setGP", gp);
    } catch (e) {
      console.log(e);
    }
  },

  async getDebt(accountId) {
    try {
      const debt = await ApiService.fetchAccountDebt(accountId);
      await store.dispatch("account/setDebt", debt);
    } catch (e) {
      console.log(e);
    }
  },
};
