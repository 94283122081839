export default {
  getItem(name) {
    const item = localStorage.getItem(name);
    if (item !== "undefined" && item !== "null") {
      return JSON.parse(item);
    } else {
      return null;
    }
  },

  setItem(name, data) {
    if (data !== undefined) {
      const item = JSON.stringify(data);
      localStorage.setItem(name, item);
    }
  },
};
