import StorageService from "../../services/StorageService";

export default {
  namespaced: true,
  state: () => ({
    isLoggedIn: false,
    verificationPayload: StorageService.getItem("verificationPayload"),
  }),
  mutations: {
    setLoggedIn(state, payload) {
      state.isLoggedIn = payload;
    },
    setVerificationPayload(state, payload) {
      state.verificationPayload = payload;
    },
  },
  actions: {
    setLoggedIn({ commit }, payload) {
      commit("setLoggedIn", payload);
      StorageService.setItem("isLoggedIn", payload);
    },
    setVerificationPayload({ commit }, payload) {
      commit("setVerificationPayload", payload);
      StorageService.setItem("verificationPayload", payload);
    },
  },
  getters: {},
};
