<template>
  <auth-form-layout>
    <template v-slot:title>Авторизоваться</template>
    <template v-slot:form>
      <div v-if="dataType === 'phone'" class="pa-3 pt-0 text-caption">
        В течение нескольких секунд на Ваш телефон поступит звонок с уникального номера или придет СМС. Вам нужно ввести последние 4 цифры этого номера или код из СМС
      </div>
      <simple-form :form-instance="form" ref="formLogin"></simple-form>
    </template>
    <template v-slot:additional-links>
      <a v-if="getCodeState" @click="setSendCodeState()">Уже есть код</a>
      <div v-else-if="showTimer">Повторно запросить код можно через <b>{{ timerText }}</b></div>
      <a v-else @click="setGetCodeState()">Получить код повторно</a>
      <div class="d-flex flex-column w-full">
        <router-link :to="{ name: 'pay-no-auth' }">Оплатить</router-link>
        <router-link :to="{ name: 'counters-no-auth' }">
          Передать показания
        </router-link>
      </div>
    </template>
    <template v-slot:additional-text>
      <div>
        Авторизуясь в кабинете, вы соглашаетесь с условиями
        <a
          target="_blank"
          href="https://xn--d1abeikdleg.xn--p1ai/lk/lk-reg/Pravila.pdf"
          >настоящих правил</a
        >.
      </div>
      <div class="mt-2">
        С политикой ООО "КРКЦ" в отношении обработки персональных данных можно
        ознакомиться
        <a
          target="_blank"
          href="https://xn--d1abeikdleg.xn--p1ai/lk/lk-reg/personal_info_agreement.pdf"
          >здесь</a
        >.
      </div>
      <download />
    </template>
  </auth-form-layout>
</template>

<script>
import SimpleForm from "@/components/forms/SimpleForm";
import LoginForm from "@/forms/LoginForm";
import AuthFormLayout from "../../components/Layouts/forms/AuthFormLayout";
import StorageService from "@/services/StorageService";
import Download from "@/views/contacts/Download";
import moment from "moment";

export default {
  name: "Login",
  components: {
    Download,
    SimpleForm,
    AuthFormLayout,
  },
  data() {
    return {
      seconds: 0,
      form: new LoginForm(),
    };
  },
  computed: {
    getCodeState() {
      return this.form.fields.code.hidden;
    },
    // тип авторизации
    dataType() {
      return this.form.type;
    },
    // нужно ли отображать таймер
    showTimer() {
      return parseInt(this.seconds) > 0;
    },
    // текст таймера
    timerText() {
      return moment.utc(this.seconds * 1000).format('mm:ss');
    },
    // реагируем на изменение секунд на форме
    timerValue() {
      return this.form.seconds;
    },
  },
  watch: {
    timerValue() {
      // забираем значение секунд с формы и запускаем таймер
      this.seconds = this.form.seconds;
      this.countDownTimer();
    },
  },
  methods: {
    countDownTimer() {
      if (this.seconds > 0) {
        setTimeout(() => {
          this.seconds -= 1;
          this.countDownTimer();
        }, 1000);
      }
    },
    setSendCodeState() {
      let login = this.form.payload.contact || "";
      if (login !== "") { //  && /.+@.+\..+/.test(login)
        StorageService.setItem("verificationPayload", {
          login: this.form.payload.contact,
        });
        return this.form.setSendCodeState(0);
      } else {
        this.$refs.formLogin.$refs.LoginForm.validate();
        // let email = this.$refs.formLogin.$refs["E-mail или телефон"][0];
        // email.focus();
      }
    },
    setGetCodeState() {
      return this.form.setGetCodeState();
    },
  },
  mounted() {
    if (this.$route.query.email) {
      this.form.payload.contact = this.$route.query.email;
    }
  },
};
</script>

<style scoped></style>
