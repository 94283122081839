import moment from "moment";

export const getFormattedDate = (date, format) => {
  return moment(date).locale("ru").format(format);
};

export const getAccrualDate = (date) => {
  let formattedDate = getFormattedDate(date, "MMMM YYYY");
  return formattedDate[0].toUpperCase() + formattedDate.slice(1);
};
