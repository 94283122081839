<template>
  <div class="top-menu-icons d-flex mr-3" :class="iconsClass">
    <div
      class="item mr-3 cursor-pointer d-flex"
      @click="item.action"
      v-for="item in items"
      :key="item.title"
    >
      <!--      <v-img-->
      <!--        v-if="item.title === 'Привязка ЛС'"-->
      <!--        alt="add account"-->
      <!--        width="20"-->
      <!--        contain-->
      <!--        class="mr-1"-->
      <!--        src="../../../../assets/icons/add_account_hover.png"-->
      <!--      />-->
      <v-icon color="white" class="mr-1">{{ item.icon }}</v-icon>
      <span class="text-button white--text">{{ item.title }}</span>
    </div>
  </div>
</template>

<script>
import AppService from "../../../../services/AppService";

export default {
  name: "TopMenuIcons",
  data() {
    return {
      items: AppService.topMenuItems,
    };
  },
  computed: {
    iconsClass() {
      return this.$vuetify.breakpoint.mdAndDown ? "flex-column" : "flex-row";
    },
  },
  methods: {
    getIconPath(iconName) {
      return "../../../../assets/icons/" + iconName;
    },
  },
};
</script>

<style scoped></style>
