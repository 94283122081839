<template>
  <div class="auth-wrapper auth-v1">
    <div class="auth-inner">
      <v-card class="auth-card d-flex flex-column-reverse flex-md-row">
        <!-- title -->
        <div class="left-block">
          <div class="pa-8 d-flex flex-column">
            <app-logo></app-logo>
            <v-img
              alt="Laptop"
              class="mt-10"
              src="../../../assets/images/auth-laptop.png"
              max-width="75%"
            />
          </div>
        </div>
        <v-card-text class="right-block pa-3 pa-md-12">
          <div class="d-flex align-center justify-center pb-6">
            <div class="d-flex align-center">
              <h2 class="text-2xl font-weight-semibold">
                <slot name="title"></slot>
              </h2>
            </div>
          </div>
          <p class="text-2xl font-weight-semibold text--primary mb-2">
            <slot name="subtitle"></slot>
          </p>
          <p class="mb-2">
            <slot name="helper-text"></slot>
          </p>

          <!-- login form -->
          <div>
            <slot name="form"></slot>
          </div>

          <!-- additional links  -->
          <div class="d-flex align-center justify-center flex-wrap mt-2">
            <slot name="additional-links"></slot>
          </div>

          <!-- additional text  -->
          <div class="d-flex align-center justify-center flex-wrap mt-2">
            <slot name="additional-text"></slot>
          </div>

          <!-- divider -->
          <div v-if="showSocialLinks" class="d-flex align-center mt-2">
            <v-divider></v-divider>
            <span class="mx-5">ИЛИ</span>
            <v-divider></v-divider>
          </div>

          <!-- social links -->
          <div v-if="showSocialLinks" class="d-flex justify-center">
            <v-btn
              v-for="link in socialLinks"
              :key="link.icon"
              icon
              class="ms-1"
            >
              <v-icon
                :color="$vuetify.theme.dark ? link.colorInDark : link.color"
              >
                {{ link.icon }}
              </v-icon>
            </v-btn>
          </div>
        </v-card-text>
      </v-card>
    </div>
  </div>
</template>

<script>
import AppService from "@/services/AppService";
import AppLogo from "../../AppLogo";

export default {
  name: "AuthFormLayout",
  components: { AppLogo },
  props: {
    needSocialLinks: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      socialLinks: AppService.socialLinks,
    };
  },
  computed: {
    showSocialLinks() {
      return this.needSocialLinks && this.socialLinks.length;
    },
  },
};
</script>

<style lang="scss">
@import "~@/plugins/vuetify/default-preset/preset/pages/auth.scss";
.auth-inner {
  .v-card {
    padding: 0;

    > .left-block {
      width: 50%;
      border-radius: 6px 0 0 6px;

      background: url("../../../assets/images/auth-bg.png") no-repeat;
      background-size: auto 100%;
    }

    > .right-block {
      width: 50%;
    }
  }
}

@media (max-width: 959px) {
  .auth-inner {
    .v-card {
      > .left-block {
        width: 100%;
      }

      > .right-block {
        width: 100%;
      }
    }
  }
}
</style>
