<template>
  <div>
    <div class="mt-5 d-flex justify-center">
      <a
        href="https://play.google.com/store/apps/details?id=com.nedolzhnikapp"
        target="_blank"
      >
        <v-img
          alt="Скачать приложение для Android в Google Play"
          width="150"
          contain
          src="../../assets/images/mobile_stores/googleplay_ru.svg"
          transition="scale-transition"
          :style="{ cursor: 'pointer' }"
        />
      </a>
    </div>
    <div class="mt-2 d-flex justify-center">
      <a
        href="https://apps.apple.com/ru/app/%D0%BD%D0%B5%D0%B4%D0%BE%D0%BB%D0%B6%D0%BD%D0%B8%D0%BA-%D1%80%D1%84/id1516347032"
        target="_blank"
      >
        <v-img
          alt="Скачать приложение для iOs в App Store"
          width="150"
          contain
          src="../../assets/images/mobile_stores/appstore_ru.svg"
          transition="scale-transition"
          :style="{ cursor: 'pointer' }"
        />
      </a>
    </div>
    <div class="mt-2 d-flex justify-center">
      <a href="https://apps.rustore.ru/app/com.nedolzhnikapp" target="_blank">
        <v-img
            alt="Скачать приложение для Android в RuStore"
            width="150"
            contain
            src="../../assets/images/mobile_stores/rustore_ru.svg"
            transition="scale-transition"
            :style="{ cursor: 'pointer' }"
        />
      </a>
    </div>
    <div class="mt-2 d-flex justify-center">
      <a href="https://appgallery.huawei.ru/app/C108102745" target="_blank">
        <v-img
            alt="Скачать приложение для Android в AppGallery"
            width="150"
            contain
            src="../../assets/images/mobile_stores/app_gallery.svg"
            transition="scale-transition"
            :style="{ cursor: 'pointer' }"
        />
      </a>
    </div>
  </div>
</template>

<script>
export default {
  name: "Download",
};
</script>

<style scoped></style>
