<template>
  <v-card class="d-flex flex-column">
    <v-card-title
      v-if="needHeader"
      class="
        align-center align-sm-start
        flex-column flex-sm-row
        justify-center justify-sm-start
      "
    >
      <slot name="title-left" class="title-slot"></slot>
      <v-spacer></v-spacer>
      <slot name="title-right" class="title-slot"></slot>
    </v-card-title>

    <v-card-text :class="cardTextClass">
      <slot name="content"></slot>
    </v-card-text>

    <v-card-actions v-if="needFooter" class="flex-grow-1">
      <slot name="footer"></slot>
    </v-card-actions>
  </v-card>
</template>

<script>
export default {
  name: "SimpleCard",
  props: {
    needFooter: {
      type: Boolean,
      default: true,
    },
    needHeader: {
      type: Boolean,
      default: true,
    },
  },
  computed: {
    cardTextClass() {
      return { "h-full": !this.needFooter };
    },
  },
};
</script>

<style scoped>
.title-slot {
  word-break: normal;
}
</style>
