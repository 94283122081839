import FormRules from "@/forms/FormRules";
import PaymentsService from "../../services/PaymentsService";
import store from "../../store";
import AppService from "../../services/AppService";

export default class NoAuthActionsForm extends FormRules {
  valid = true;
  name = "NoAuthActionsForm";
  isCheckLsState = true;
  checkData = null;

  payload = {
    account: "",
  };

  fields = {
    account: {
      label: "Лицевой счет",
      type: "text",
      rules: [this.required("Введите номер ЛС")],
      required: true,
      disabled: false,
    },
  };

  buttons = {
    checkLs: {
      label: "Проверить ЛС",
      action: () => this.checkLs(this.payload.account),
      type: "submit",
      block: true,
      hidden: false,
      useLoading: true,
    },
  };

  async checkLs(ls) {
    try {
      this.checkData = await PaymentsService.checkLs(ls);
      this.setActionState();
    } catch (e) {
      store.commit("app/setAlert", {
        shown: true,
        text: "Лицевой счет не найден",
        color: "warning",
      });
      throw e;
    } finally {
      AppService.unsetLoading("get_exists");
    }
  }

  setCheckLsState() {
    for (let field in this.fields) {
      this.fields[field].hidden = field !== "account";
      if (field === "account") {
        this.fields[field].disabled = false;
      }
    }

    for (let button in this.buttons) {
      this.buttons[button].hidden = button !== "checkLs";
    }

    this.isCheckLsState = true;
  }

  setActionState() {
    for (let field in this.fields) {
      this.fields[field].disabled = field === "account";
      this.fields[field].hidden = false;
    }

    for (let button in this.buttons) {
      this.buttons[button].hidden = button === "checkLs";
    }

    this.isCheckLsState = false;
  }
}
