<template>
  <simple-dialog v-bind="$attrs" @close-dialog="$emit('close-dialog')">
    <template v-slot:dialog-content>
      <simple-card class="confirm-container">
        <template v-slot:title-left>{{ title }}</template>
        <template v-slot:content>
          <div :class="questionClass">{{ question }}</div>
        </template>
        <template v-slot:footer>
          <div class="d-flex justify-end w-full">
            <v-btn color="primary" class="mr-2" small @click="$emit('confirm')"
              >Да</v-btn
            >
            <v-btn small @click="$emit('cancel')">Отмена</v-btn>
          </div>
        </template>
      </simple-card>
    </template>
  </simple-dialog>
</template>

<script>
import SimpleDialog from "./SimpleDialog";
import SimpleCard from "../cards/SimpleCard";
export default {
  name: "ConfirmDialog",
  components: { SimpleDialog, SimpleCard },
  props: {
    title: String,
    question: String,
    question_class: {
      type: String,
      default: "",
    },
  },
  computed: {
    questionClass() {
      return (
        "text-subtitle-2" +
        (this.question_class !== "" ? " " : "") +
        this.question_class
      );
    },
  },
};
</script>

<style scoped>
.confirm-container {
  width: 320px;
}
.confirm-dialog-red-text {
  color: #f44336 !important;
}
</style>
