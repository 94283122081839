import NoAuthActionsForm from "./NoAuthActionsForm";
import CountersService from "../../services/CountersService";

export default class CountersNoAuthForm extends NoAuthActionsForm {
  _counters = [];

  get counters() {
    return this._counters;
  }

  set counters(counters) {
    this._counters = counters;
  }

  buttons = {
    ...this.buttons,
    findAnotherAccount: {
      label: "Найти другой ЛС",
      action: () => {
        this.setCheckLsState();
        this.counters = [];
      },
      type: "submit",
      block: true,
      hidden: true,
      useLoading: false,
    },
  };

  async checkLs(ls) {
    try {
      await super.checkLs(ls);
      await this.getCounters(ls);
    } catch (e) {
      console.log(e);
    }
  }

  async getCounters(ls) {
    this.counters = await CountersService.getNoAuthCounters(ls);
  }
}
