import store from "../store";
import ApiService from "./ApiService";
import AccrualsService from "./AccrualsService";
import AppService from "./AppService";
import PaymentsService from "./PaymentsService";

export default {
  currentAddressesTableHeaders: [
    {
      text: "Лицевой Счет",
      value: "account",
    },
    {
      text: "Адрес",
      value: "address",
    },
    {
      text: "Действия",
      value: "action",
      class: "text-right",
    },
  ],
  getInfoCardItems(binding) {
    return {
      leftItems: [
        {
          title: "Номер счета",
          value: binding.number,
        },
        // {
        //   title: 'Телефон',
        //   value: '-'
        // },
        {
          title: "Число проживающих",
          value: binding.persons.total,
        },
        {
          title: "Число прописанных",
          value: binding.persons.registered,
        },
      ],
      rightItems: [
        {
          title: "Общая площадь",
          value: binding.address.space.common,
        },
        {
          title: "Жилая площадь",
          value: binding.address.space.live,
        },
        {
          title: "Тип собственности",
          value: binding.owner.type,
        },
      ],
    };
  },

  getFormattedBindings(bindings) {
    return bindings?.map((binding) => {
      return {
        id: binding.id,
        account: `№ ${binding.number}`,
        address: binding.address.full,
        action: "action",
      };
    });
  },

  getFlatCardInfo(activeBinding) {
    let info = [];
    if (activeBinding) {
      info = [
        {
          title: "Лицевой счет",
          value: activeBinding.number,
          numbers: activeBinding?.numbers.toString() || "",
        },
        {
          title: "Адрес",
          value: activeBinding.address.full,
          outer: activeBinding.outer,
        },
        { title: "Владелец", value: activeBinding.owner.name },
      ];
    }

    return info;
  },

  setUserInfo({ token, login }) {
    store.dispatch("auth/setLoggedIn", !!token).then();
    store.dispatch("user/setUserData", { login, token }).then();
  },

  async getUserBindings() {
    const bindings = await ApiService.fetchUserBindings();
    AppService.unsetLoading("get_accounts");
    await store.dispatch("user/setBindings", bindings);
  },

  async getUserCards() {
    const cards = await ApiService.fetchUserCards();
    await store.dispatch("user/setCards", cards);
  },

  async getRouteByBindings() {
    await this.getUserBindings();
    await this.getUserCards();
    const accountId = store.getters["user/activeBindingId"];
    let route = "bindings";

    if (accountId) {
      await this.getInitialData(accountId);
      AppService.unsetLoading("get_charges");
      AppService.unsetLoading("get_payments");

      route = "home";
    }

    const time = 1000 * 60 * 5; // 5 минут
    store.commit("app/setUpdate", Date.now() + time);
    return route;
  },

  async checkLsPrepare(ls) {
    const ukArr = await ApiService.checkLsPrepare(ls);
    return ukArr.map((uk) => {
      return { id: uk.id, name: uk.name };
    });
  },

  async checkLs(ls, uk) {
    const ways = await ApiService.checkLs(ls, uk);
    const formattedWays = {};
    ways.forEach((way) => {
      formattedWays[way.code] = way;
    });
    return formattedWays;
  },

  async bindLs(payload) {
    try {
      const account = await ApiService.bindLs(payload);
      let accountId = account['id'] || 0;
      await this.getUserBindings();

      // устанавливаем привязку
      if (accountId > 0) {
        await this.setActiveBinding(accountId);
      }

      accountId = store.getters["user/activeBindingId"];

      if (accountId) {
        await this.getInitialData(accountId);
      }

      return true;
    } catch (error) {
      console.log("bindLs error", error);
      return false;
    }
  },

  async deleteLsBinding(id) {
    try {
      await ApiService.deleteLsBinding(id);
      await store.dispatch("user/deleteBindingById", id);
    } catch (error) {
      console.log(error);
    }
  },

  async setActiveBinding(accountId) {
    await store.dispatch("user/setActiveBindingById", accountId);
    return this.getInitialData(accountId);
  },

  async getInitialData(accountId) {
    await Promise.all([
      AccrualsService.getCharges(accountId),
      AccrualsService.getPayments(accountId),
      AccrualsService.getBalance(accountId),
      PaymentsService.getRecurrents(accountId),
      PaymentsService.getGP(accountId),
      PaymentsService.getDebt(accountId),
    ]);
    AppService.unsetLoading("get_charges");
    AppService.unsetLoading("get_payments");
  },
};
