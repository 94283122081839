<template>
  <v-app-bar
    app
    flat
    absolute
    color="transparent"
    id="app-header"
    :height="headerHeight"
  >
    <div
      class="app-content-container boxed-container w-full"
      :style="appContentContainerStyle"
    >
      <!-- TODO разобраться -->
      <div style="padding: 0 12px" class="container">
        <v-card class="w-full">
          <v-card-text>
            <div v-if="isMobile" class="mobile-card-text">
              <v-app-bar-nav-icon
                class="d-block d-lg-none me-2 app-bar-nav-icon"
                @click="updateDrawerState(!isDrawerOpen)"
              ></v-app-bar-nav-icon>
              <app-logo style="float: right"></app-logo>
              <v-spacer></v-spacer>
              <simple-select
                dense
                outlined
                hide-details
                color="white"
                v-model="currentBinding"
                v-if="showSelect"
                :items="formattedBindings"
                item-value="id"
                item-text="account"
                @item-changed="onBindingChange"
              ></simple-select>
              <div
                v-else
                style="padding-top: 14px; display: inline-block"
                class="text-h6 white--text"
              >
                Нет привязанных ЛС
              </div>
              <v-btn
                style="float: right"
                @click="isLogoutDialogOpen = true"
                color="white"
                icon
              >
                <v-icon>mdi-logout-variant</v-icon>
              </v-btn>
              <v-spacer></v-spacer>
              <div
                style="padding-top: 10px; display: inline-block"
                class="text-h6 white--text"
              >
                {{ activeBindingOwner }}
              </div>
            </div>
            <div v-else class="d-flex align-start">
              <!--   todo проработать левое боковое меню для маленьких экранов   -->
              <div class="d-flex flex-column">
                <div class="d-flex">
                  <v-fade-transition>
                    <app-logo v-if="!isDrawerOpen"></app-logo>
                  </v-fade-transition>
                  <simple-select
                    dense
                    outlined
                    hide-details
                    color="white"
                    v-model="currentBinding"
                    v-if="showSelect"
                    :items="formattedBindings"
                    item-value="id"
                    item-text="account"
                    @item-changed="onBindingChange"
                  ></simple-select>
                  <v-fade-transition>
                    <app-logo-m-d v-if="!isDrawerOpen"></app-logo-m-d>
                  </v-fade-transition>
                </div>
                <!--              <h1 class="text-h5 mt-3">Личный кабинет</h1>-->
              </div>
              <v-spacer></v-spacer>
              <div
                class="
                  d-flex
                  flex-column
                  justify-space-between
                  align-self-stretch align-end
                "
              >
                <div class="d-flex align-center">
                  <top-menu-icons v-if="activeBinding"></top-menu-icons>
                  <!--                <theme-switcher class="mr-3"></theme-switcher>-->
                  <!--                <app-bar-user-menu></app-bar-user-menu>-->
                  <span class="text-h6 white--text">{{
                    activeBindingOwner
                  }}</span>
                  <!--                  <v-img-->
                  <!--                    @click="isLogoutDialogOpen=true"-->
                  <!--                    alt="Logout"-->
                  <!--                    width="16"-->
                  <!--                    class="ml-1 cursor-pointer"-->
                  <!--                    contain-->
                  <!--                    src="../../../assets/icons/logout.png"-->
                  <!--                  />-->
                  <v-btn @click="isLogoutDialogOpen = true" color="white" icon>
                    <v-icon>mdi-logout-variant</v-icon>
                  </v-btn>
                </div>
                <!--              <div class="text-h6">-->
                <!--                Бонусы: 1490-->
                <!--              </div>-->
              </div>
            </div>
          </v-card-text>
        </v-card>
      </div>
    </div>
    <confirm-dialog
      :is-open="isLogoutDialogOpen"
      title="Выход"
      question="Выйти из личного кабинета?"
      @close-dialog="isLogoutDialogOpen = false"
      @confirm="logout"
      @cancel="isLogoutDialogOpen = false"
    ></confirm-dialog>
  </v-app-bar>
</template>

<script>
// import ThemeSwitcher from "@/components/Layouts/app-header/components/ThemeSwitcher";
// import AppBarUserMenu from "@/components/Layouts/app-header/components/AppBarUserMenu";
import AppLogo from "../../AppLogo";
import AppLogoMD from "../../AppLogoMD";
import SimpleSelect from "../../selects/SimpleSelect";
import { mapGetters, mapState } from "vuex";
import UserService from "../../../services/UserService";
import AppService from "../../../services/AppService";
import TopMenuIcons from "./components/TopMenuIcons";
import ConfirmDialog from "../../dialogs/ConfirmDialog";
import AuthService from "../../../services/AuthService";

export default {
  name: "AppHeader",
  components: {
    TopMenuIcons,
    // ThemeSwitcher,
    // AppBarUserMenu,
    ConfirmDialog,
    AppLogo,
    AppLogoMD,
    SimpleSelect,
  },
  props: {
    isDrawerOpen: {
      type: Boolean,
      default: null,
    },
    appContentContainerStyle: Object,
  },
  data() {
    return {
      headerHeight: "150px",
      app: AppService,
      isLogoutDialogOpen: false,
    };
  },
  methods: {
    updateDrawerState(val) {
      this.$emit("update:is-drawer-open", val);
    },
    async onBindingChange(accountId) {
      await UserService.setActiveBinding(accountId);
    },
    async logout() {
      await AuthService.logoutUser();
      this.isLogoutDialogOpen = false;
      this.$router.push({ name: "login" }).then(() => {});
      window.location.href = "https://xn--d1abeikdleg.xn--p1ai/";
    },
  },
  computed: {
    ...mapState("user", {
      bindings: (state) => state.bindings,
      activeBinding: (state) => state.activeBinding,
    }),
    ...mapGetters("user", ["activeBindingOwner"]),
    currentBinding() {
      return this.activeBinding ? this.activeBinding.id : null;
    },
    showSelect() {
      return this.bindings && this.bindings.length;
    },
    formattedBindings() {
      return UserService.getFormattedBindings(this.bindings);
    },
    ...mapState("app", {
      isMobile: (state) => state.isMobile,
    }),
  },
};
</script>

<style lang="scss">
.v-app-bar ::v-deep {
  .v-toolbar__content {
    padding: 0;

    .app-bar-search {
      .v-input__slot {
        padding-left: 18px;
      }
    }
  }

  .container {
    /*padding-top: 0;*/
    /*padding-bottom: 0;*/
    padding: 0;
  }
}

#app-header {
  .v-card {
    background: url("../../../assets/images/header_background.jpg") no-repeat;
    background-position: top -200px;

    .v-card__text {
      background: rgba(78, 126, 201, 0.5);

      .v-select__selection {
        color: #fff !important;
      }

      .v-text-field--outlined:not(.v-input--is-focused):not(.v-input--has-state)
        > .v-input__control
        > .v-input__slot
        fieldset {
        color: #fff;
      }

      .v-input__icon.v-input__icon--append > .v-icon {
        color: #fff;
      }
    }
  }
  .v-app-bar__nav-icon {
    margin: 0;
    color: #ffffff;
    display: inline-block !important;
  }
}

@media only screen and (max-width: 599.98px) {
  #app-header .v-toolbar__content {
    padding: 0 !important;
    min-height: 150px;
  }
  #app-header .v-card__text {
    padding: 10px !important;
  }
  #app-header .mobile-card-text .simple-select {
    display: inline-block;
    margin-top: 10px;
    width: calc(100% - 50px);
  }
  #app-header .app-content-container .mobile-card-text {
    height: 130px;
  }
  /*#app-header .d-flex {
    display: block !important;
  }
  #app-header .d-flex {
    display: block !important;
  }*/
}
</style>
