import { mapState } from "vuex";

export default {
  computed: {
    ...mapState("app", ["loading", "loadingIdentifiers"]),
    needLoadingIndicator() {
      return (
        this.loading &&
        this.loadingIdentifiers &&
        this.loadingIdentifiers.includes(this.loadingFlag)
      );
    },
  },
};
