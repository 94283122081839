import Vue from "vue";
import Vuex from "vuex";
import app from "./modules/app";
import auth from "./modules/auth";
import user from "./modules/user";
import account from "./modules/account";

Vue.use(Vuex);

export default new Vuex.Store({
  state: {},
  mutations: {},
  actions: {},
  modules: { app, auth, user, account },
});
