<template>
  <v-col v-bind="$attrs" class="pa-2">
    <v-skeleton-loader
      style="min-height: 180px"
      v-if="loader"
      type="image"
      v-bind="$attrs"
    ></v-skeleton-loader>
    <simple-card
      v-else
      class="counter-card"
      :style="getCardStyle()"
      :need-footer="false"
    >
      <template v-slot:title-left>
        <div class="d-flex flex-column align-center w-full">
          <div v-if="showIcon" class="mb-1">
            <v-img alt="Logo" class="shrink" contain :src="iconSrc" width="20">
            </v-img>
          </div>
          <div class="text-body-2 text-center counter-name">
            {{ counter.service.name }}
          </div>
        </div>
      </template>
      <template v-slot:content>
        <div class="h-full d-flex justify-space-between flex-column">
          <span class="text-caption">Заводской номер: {{ counter.name }}</span>
          <div>
            <v-text-field
              v-if="!isBlocked(counter)"
              dense
              placeholder="00000"
              outlined
              :disabled="!sendAvailable"
              v-model="indication"
              :rules="inputRules"
            ></v-text-field>
            <div
              v-else
              class="mt-2 mb-2 ml-0 mr-0 error--text"
            >
              Истек срок поверки счетчика, рекомендуем обратиться в ЕДС!
            </div>
            <div class="d-flex justify-space-between align-center">
              <div class="d-flex flex-column align-start">
                <span class="mb-1">ЕПД: {{ getEpdIndication(counter) }}</span>
                <span>Абонент: {{ getAbonentIndication(counter) }}</span>
              </div>
              <v-btn
                v-if="!isBlocked(counter)"
                small
                color="primary"
                fab
                :disabled="!isValidIndication"
                :loading="loading"
                @click="changeDialogState(true)"
              >
                <v-icon dark>mdi-file-send-outline</v-icon>
              </v-btn>
            </div>
            <div class="d-flex justify-space-between align-center">
              <div class="d-flex flex-column align-start mt-1 counter-info">
                <span class="mb-1"
                  >Дата передачи с {{ getFirstDay(counter) }} по
                  {{ getLastDay(counter) }}</span
                >
                <span>Дата поверки: {{ getVerificationPeriod(counter) }}</span>
              </div>
            </div>
          </div>
        </div>
        <counters-confirm-dialog
          v-if="isCounterConfirmOpened"
          :is-open="isCounterConfirmOpened"
          :value-old="lastIndication"
          :value="newIndication"
          :name="counter.name"
          @close-dialog="changeDialogState(false)"
          @confirm="sendIndications"
          @cancel="changeDialogState(false)"
        ></counters-confirm-dialog>
      </template>
    </simple-card>
  </v-col>
</template>

<script>
import SimpleCard from "./SimpleCard";
import CountersService from "../../services/CountersService";
import CountersConfirmDialog from "@/views/counters/components/CountersConfirmDialog";
import moment from "moment";

export default {
  name: "CounterCard",
  components: { SimpleCard, CountersConfirmDialog },
  props: {
    counter: {
      type: Object,
    },
    loader: {
      type: Boolean,
      default: false,
    },
    isNoAuth: {
      type: Boolean,
      default: false,
    },
    account: [String, null],
  },
  data() {
    return {
      loading: false,
      indication: "",
      inputRules: [(v) => !isNaN(+v)],
      isCounterConfirmOpened: false,
    };
  },
  methods: {
    getCardStyle() {
      return CountersService.getCounterCardStyle();
    },
    getEpdIndication(counter) {
      return `${counter.last_indication?.epd} ${counter.measure_unit}`;
    },
    getAbonentIndication(counter) {
      return `${counter.last_indication?.abonent} ${counter.measure_unit}`;
    },
    getFirstDay(counter) {
      return counter.receive_period?.first_day;
    },
    getLastDay(counter) {
      return counter.receive_period?.last_day;
    },
    getVerificationPeriod(counter) {
      let date = counter?.verification_period || "";
      if (date !== "") {
        return moment(String(date)).format("DD.MM.YYYY");
      }
      return "Не указана";
    },
    isBlocked(counter) {
      return counter?.verification_blocked || false;
    },
    async sendIndications() {
      this.loading = true;
      this.changeDialogState(false);

      if (this.isNoAuth && this.account) {
        await CountersService.sendNoAuthIndications(
          { ...this.counter, last_indication: { epd: this.indication } },
          this.account
        );
        const counters = await CountersService.getNoAuthCounters(this.account);
        this.indication = "";
        this.$emit("update-counters", counters);
      } else {
        await CountersService.sendIndications({
          ...this.counter,
          last_indication: { epd: this.indication },
        });
      }
      this.loading = false;
      this.resetCounterIndication();
    },
    resetCounterIndication() {
      this.counter.last_indication.epd = "";
    },
    changeDialogState(isOpen) {
      this.isCounterConfirmOpened = isOpen;
    },
  },
  computed: {
    lastIndication() {
      return parseFloat(
        this.counter.last_indication?.abonent ||
          this.counter.last_indication?.epd ||
          0
      );
    },
    newIndication() {
      return parseFloat(this.indication);
    },
    availableDate() {
      return CountersService.isSendAvailableToday(this.counter);
    },
    sendAvailable() {
      return this.availableDate && !this.loading;
    },
    isValidIndication() {
      let valid = true;

      if ((this.indication || "") === "") {
        return false;
      }

      for (let rule of this.inputRules) {
        if (!rule(this.indication)) {
          valid = false;
          break;
        }
      }

      return this.sendAvailable && valid;
    },
    showIcon() {
      return Boolean(this.counter.img);
    },
    iconSrc() {
      return this.counter.img
        ? process.env.VUE_APP_IMAGE_HOST + this.counter.img
        : null;
    },
  },
};
</script>

<style lang="scss">
.counter-card {
  position: relative;
  height: 100%;
  .v-card__title {
    padding-bottom: 0;

    .counter-name {
      word-break: normal;
    }
  }

  .v-card__actions {
    padding-bottom: 0 !important;
    &:last-child {
      padding-bottom: 0 !important;
    }
  }

  input {
    text-align: center;
  }

  .counter-info {
    font-size: 0.7rem;
  }
}
</style>
