<template>
  <div>
    <h3 class="text-body-2 text-left font-weight-semibold mb-4 mt-4">
      Для оплаты Вам потребуется:
    </h3>
    <div class="text-left pl-sm-4">
      <div v-for="item in needForPayment" :key="item">
        <v-icon>mdi-circle-medium</v-icon> {{ item }}
      </div>
    </div>
    <div class="mt-4 text-left">
      <div>Оплата услуг с производится помощью банковской карты.</div>
      <div>
        После нажатия на кнопку «Оплатить» информация о платеже будет проверена,
        и Вы будете перенаправлены на защищенную страницу, расположенную на
        сервере Банка, где осуществите платеж и будете возвращены обратно на наш
        сайт.
      </div>
    </div>
    <div class="mt-4">
      <v-img max-width="750px" src="@/assets/images/banks.jpg"></v-img>
    </div>
  </div>
</template>

<script>
export default {
  name: "PaymentAdditionalInfo",
  data() {
    return {
      needForPayment: [
        "Номер карты, имя держателя карты;",
        "Срок окончания действия вашей карты, месяц/год;",
        "CVV код для карт Visa / CVC код для Master Card;",
      ],
    };
  },
};
</script>

<style scoped></style>
