<template>
  <simple-dialog v-bind="$attrs" @close-dialog="$emit('close-dialog')">
    <template v-slot:dialog-content>
      <simple-card class="confirm-container">
        <template v-slot:title-left>Передать показания</template>
        <template v-slot:content>
          <div>
            По прибору учета № <span class="font-weight-bold">{{ name }}</span>
          </div>
          <div class="mt-5">
            Предыдущее показание:
            <span class="font-weight-bold">{{ valueOld }}</span>
          </div>
          <div class="mt-2">
            Введено показание: <span class="font-weight-bold">{{ value }}</span>
          </div>
          <v-checkbox
            v-model="confirmCheckbox"
            outlined
            hide-details
            class="mt-5 pt-0 mb-0"
            label="Показания подтверждаю"
          ></v-checkbox>
        </template>
        <template v-slot:footer>
          <div class="d-flex justify-end w-full">
            <v-btn
              :disabled="!confirmCheckbox"
              color="primary"
              class="mr-2"
              small
              @click="$emit('confirm')"
            >
              Отправить
            </v-btn>
            <v-btn small @click="$emit('cancel')">Отмена</v-btn>
          </div>
        </template>
      </simple-card>
    </template>
  </simple-dialog>
</template>

<script>
import SimpleDialog from "@/components/dialogs/SimpleDialog";
import SimpleCard from "@/components/cards/SimpleCard";
export default {
  name: "CountersConfirmDialog",
  components: { SimpleDialog, SimpleCard },
  props: {
    valueOld: Number,
    value: Number,
    name: String,
  },
  data() {
    return {
      confirmCheckbox: false,
    };
  },
};
</script>

<style scoped>
.confirm-container {
  width: 320px;
}
</style>
