import StorageService from "../../services/StorageService";

export default {
  namespaced: true,
  state: () => ({
    login: StorageService.getItem("login"),
    bindings: StorageService.getItem("bindings"),
    activeBinding: StorageService.getItem("activeBinding"),
    cards: StorageService.getItem("cards"),
  }),
  mutations: {
    setLogin(state, payload) {
      state.login = payload;
    },
    setBindings(state, payload) {
      state.bindings = payload;
    },
    setActiveBinding(state, payload) {
      state.activeBinding = payload;
    },
    setCards(state, payload) {
      state.cards = payload;
    },
  },
  actions: {
    setUserData({ commit }, { token, login }) {
      commit("setLogin", login);
      StorageService.setItem("login", login);
      StorageService.setItem("token", token);
    },
    setBindings({ commit, dispatch }, bindings) {
      commit("setBindings", bindings);
      StorageService.setItem("bindings", bindings);

      if (bindings && bindings.length > 0) {
        const existingActiveBinding = StorageService.getItem("activeBinding");
        let activeBinding = null;

        if (existingActiveBinding && existingActiveBinding.id) {
          activeBinding = bindings.find(
            (binding) => binding.id === existingActiveBinding.id
          );

          if (activeBinding) {
            return dispatch("setActiveBinding", activeBinding);
          }
        } else {
          dispatch("setActiveBinding", bindings[0]);
        }
      }
    },
    setActiveBinding({ commit }, payload) {
      commit("setActiveBinding", payload);
      StorageService.setItem("activeBinding", payload);
    },
    setActiveBindingById({ commit, state }, id) {
      const binding = state.bindings?.find((binding) => binding.id === id);
      if (binding) {
        commit("setActiveBinding", binding);
        StorageService.setItem("activeBinding", binding);
      }
    },
    deleteBindingById({ dispatch, state: { bindings, activeBinding } }, id) {
      const newBindings = bindings.filter((binding) => binding.id !== id);
      dispatch("setBindings", newBindings);

      if (!newBindings.length) {
        return dispatch("setActiveBinding", null);
      }

      let newActiveBinding = null;
      if (id === activeBinding.id) {
        newActiveBinding = newBindings[0];
        // console.log("удаляем активную привязку, новая", newActiveBinding);
        dispatch("setActiveBinding", newActiveBinding);
      }
    },
    addNewBinding({ dispatch, state }, payload) {
      const currentBindings = state.bindings;
      const newBindings = [...currentBindings, payload];
      dispatch("setBindings", newBindings);
    },
    setCards({ commit }, payload) {
      commit("setCards", payload);
      StorageService.setItem("cards", payload);
    }
  },
  getters: {
    bindingById: (state) => (id) => {
      return state.bindings?.find((binding) => binding.id === id);
    },
    activeBindingId: (state) => state.activeBinding && state.activeBinding.id,
    activeBindingOwner: (state) => state.activeBinding && state.activeBinding.owner.name,
    activeBindingLs: (state) => state.activeBinding && state.activeBinding.number,
    activeBindingOuter: (state) => state.activeBinding && state.activeBinding.outer,
    cards: (state) => state.cards,
  },
};
