<template>
  <v-skeleton-loader
    height="300px"
    v-if="needLoadingIndicator"
    type="image"
    v-bind="$attrs"
  ></v-skeleton-loader>
  <simple-table
    v-else
    :showFooter="false"
    :headers="headers"
    :rows="rows"
    :items-per-page="15"
  ></simple-table>
</template>

<script>
import SimpleTable from "../../../components/tables/SimpleTable";
import AccrualsService from "../../../services/AccrualsService";
import loadingIndicator from "../../../shared/mixins/loadingIndicator";

export default {
  name: "AccrualsDetails",
  components: { SimpleTable },
  mixins: [loadingIndicator],
  props: {
    rows: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      headers: AccrualsService.detailsHeaders,
      loadingFlag: "get_charges",
    };
  },
};
</script>

<style scoped></style>
