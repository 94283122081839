<template>
  <div class="auth-wrapper auth-v1 no-auth-form-layout">
    <div class="auth-inner no-auth-actions">
      <v-card class="auth-card">
        <!-- logo -->
        <v-card-title
          class="d-flex flex-column align-center justify-center pt-7 pb-0"
        >
          <app-logo class="align-self-start"></app-logo>
          <slot name="image"></slot>
          <div class="d-flex align-center">
            <h2 class="text-2xl font-weight-semibold">
              <slot name="title"></slot>
            </h2>
          </div>
        </v-card-title>

        <!-- title -->
        <v-card-text>
          <p class="text-2xl font-weight-semibold text--primary mb-2">
            <slot name="subtitle"></slot>
          </p>
          <p class="mb-2">
            <slot name="helper-text"></slot>
          </p>
        </v-card-text>

        <!-- login form -->
        <v-card-text>
          <slot name="form"></slot>
        </v-card-text>

        <!-- additional links  -->
        <v-card-text class="d-flex align-center justify-center flex-wrap mt-2">
          <slot name="additional-links"></slot>
        </v-card-text>
      </v-card>
    </div>
  </div>
</template>

<script>
import AppService from "@/services/AppService";
import AppLogo from "../../AppLogo";

export default {
  name: "AuthFormLayout",
  components: { AppLogo },
  props: {
    needSocialLinks: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      socialLinks: AppService.socialLinks,
    };
  },
  computed: {
    showSocialLinks() {
      return this.needSocialLinks && this.socialLinks.length;
    },
  },
};
</script>

<style lang="scss">
@import "~@/plugins/vuetify/default-preset/preset/pages/auth.scss";

.no-auth-form-layout > .auth-inner > .auth-card > .v-card__title {
  background: linear-gradient(rgba(78, 126, 201, 0.5), #fff, #fff) bottom -50%;
}
</style>
