import axios from "axios";
import store from "../store";
import AuthService from "./AuthService";

const config = {
  baseURL: process.env.VUE_APP_BASE_API_URL || "http:127.0.0.1:8000/api",
};

// const fileEndpoints = [
//   'epd'
// ]

const httpService = axios.create(config);

const requestInterceptor = (config) => {
  if (AuthService.token) {
    config.headers["x-profile-token"] = AuthService.token;
  }
  config.headers["x-api-token"] = process.env.VUE_APP_KEY;

  const identifier = getLoadingIdentifier(config);
  store.commit("app/setLoadingIdentifier", identifier);
  store.commit("app/setLoading", true);
  return config;
};

const successResponseInterceptor = (response) => {
  // const endpoint = getRequestEndpoint(response.config)
  // if (fileEndpoints.includes(endpoint)) {
  //     return response.data
  // }

  if (response.data && response.data.data) {
    return response.data.data;
  } else if (response.data) {
    return response.data;
  } else {
    return response;
  }
};

const errorResponseInterceptor = async (error) => {
  const identifier = getLoadingIdentifier(error.config);
  store.commit("app/unsetLoadingIdentifier", identifier);
  store.commit("app/setLoading", false);

  if (error.response) {
    if (error.response.status === 401) {
      AuthService.logoutUser();
    } else {
      let errorMessage = error.response?.data?.message;
      if (!errorMessage) {
        // разбор результата ошибки
        if (error.response.data instanceof Blob) {
          let mes = JSON.parse(await error.response.data.text());
          errorMessage = mes?.message;
        }
      }
      errorMessage =
        errorMessage && error.response.status !== 500
          ? errorMessage
          : "Ошибка ответа от сервера";
      store.commit("app/setAlert", {
        shown: true,
        text: errorMessage,
        color: "error",
      });
    }
  }

  return Promise.reject(error);
};

const getLoadingIdentifier = (config) => {
  let endpoint = getRequestEndpoint(config);

  return `${config.method}_${endpoint}`;
};

const getRequestEndpoint = (config) => {
  let endpoint = config.url.split("/");
  return endpoint[endpoint.length - 1];
};

httpService.interceptors.request.use(requestInterceptor);
httpService.interceptors.response.use(
  successResponseInterceptor,
  errorResponseInterceptor
);

export default httpService;
