<template>
  <v-form :ref="form.name" v-model="form.valid">
    <div v-for="(field, key) in form.fields" :key="key">
      <v-textarea
        v-if="!field.hidden && field.type === 'textarea'"
        :disabled="field.disabled"
        v-model="form.payload[key]"
        :label="field.label"
        :placeholder="field.placeholder || ''"
        :append-icon="field.icon || null"
        :rules="field.rules"
        :ref="field.label"
        outlined
        class="mb-1"
      ></v-textarea>
      <v-checkbox
        v-if="!field.hidden && field.type === 'checkbox'"
        :disabled="field.disabled"
        :rules="field.rules"
        v-model="form.payload[key]"
        :ref="field.label"
        outlined
        hide-details
        class="mt-0 pt-0 mb-2"
      >
        <template v-slot:label>
          <span class="text-caption">{{ field.label }}</span>
        </template>
      </v-checkbox>
      <v-file-input
        dense
        v-else-if="!field.hidden && field.type === 'file'"
        :disabled="field.disabled"
        v-model="form.payload[key]"
        :label="field.label"
        :placeholder="field.placeholder || ''"
        :rules="field.rules"
        :ref="field.label"
        outlined
        class="mb-1"
      ></v-file-input>
      <simple-select
        dense
        v-else-if="!field.hidden && field.type === 'select'"
        :disabled="field.disabled"
        @item-changed="field.itemChangeHandler"
        v-model="form.payload[key]"
        :label="field.label"
        :placeholder="field.placeholder || ''"
        :append-icon="field.icon || null"
        :rules="field.rules"
        :ref="field.label"
        :items="field.items"
        outlined
        class="mb-1"
        item-value="id"
        item-text="name"
      ></simple-select>
      <v-text-field
        dense
        v-else-if="
          !field.hidden && (field.type === 'text' || field.type === 'email')
        "
        :disabled="field.disabled"
        v-model="form.payload[key]"
        :type="field.type"
        :label="field.label"
        :placeholder="field.placeholder || ''"
        :append-icon="field.icon || null"
        :rules="field.rules"
        :ref="field.label"
        :hide-details="form.valid"
        :class="{ 'mb-4': form.valid }"
        @keydown.enter.prevent="inputEnterHandler(field.onEnter)"
        outlined
      ></v-text-field>
    </div>

    <div class="d-flex flex-wrap" :class="[btnAlignment]">
      <v-btn
        v-for="button in form.buttons"
        :key="button.label"
        :loading="button.useLoading && loading"
        v-show="!button.hidden"
        :disabled="disableBtn(button.type)"
        :color="button.color || 'primary'"
        :class="button.class || ''"
        @click="buttonHandler(button)"
        :block="button.block"
      >
        {{ button.label }}
        <v-img
          v-if="button.logo"
          class="ml-2"
          :src="button.logo"
          height="24"
          width="24"
          max-height="24"
          max-width="24"
        />
      </v-btn>
    </div>
  </v-form>
</template>

<script>
import { mapState } from "vuex";
import SimpleSelect from "../selects/SimpleSelect";

export default {
  name: "SimpleForm",
  components: { SimpleSelect },
  props: ["formInstance", "buttonsAlignment"],
  data() {
    return {
      form: this.formInstance,
    };
  },
  methods: {
    disableBtn(btnType) {
      return btnType === "submit" && !this.form.valid;
    },
    async buttonHandler(button) {
      await button.action();

      if (button.resetForm) {
        this.$refs[this.form.name].resetValidation();
      }
    },
    inputEnterHandler(handler) {
      if (handler) {
        handler();
      }
    },
  },
  computed: {
    btnAlignment() {
      return `justify-${this.buttonsAlignment ?? "start"}`;
    },
    ...mapState("app", ["loading"]),
  },
};
</script>

<style scoped>
.sbp-text-color {
  color: #f5f1e8 !important;
}
</style>
