import NoAuthActionsForm from "./NoAuthActionsForm";
import PaymentsService from "../../services/PaymentsService";
import LogoSbp from "@/assets/icons/logo_sbp.svg";

export default class PayNoAuthForm extends NoAuthActionsForm {
  valid = true;
  name = "PayNoAuthForm";

  payload = {
    ...this.payload,
    sum: "",
    email: "",
  };

  fields = {
    ...this.fields,
    sum: {
      label: "Сумма",
      type: "text",
      rules: [this.required("Введите сумму"), this.validPaymentSum()],
      required: true,
      hidden: true,
    },
    email: {
      label: "Email",
      type: "email",
      rules: [this.validEmail()],
      required: false,
      hidden: true,
    },
  };

  buttons = {
    ...this.buttons,
    goToPay: {
      label: "Перейти к оплате",
      action: () => this.redirectToPaymentPage(false),
      type: "submit",
      block: true,
      hidden: true,
      useLoading: true,
    },
    goToPaySbp: {
      label: "Оплатить СБП",
      type: "submit",
      block: true,
      hidden: true,
      useLoading: true,
      color: "#1d1346",
      logo: LogoSbp,
      class: "sbp-text-color mt-3",
      action: () => this.redirectToPaymentPage(true),
    },
  };

  async checkLs(ls) {
    await super.checkLs(ls);

    if (this.checkData && this.checkData.non_insurance) {
      this.payload.sum = this.checkData.non_insurance;
    }
  }

  async redirectToPaymentPage(sbp) {
    const link = await PaymentsService.getPaymentNoAuthLink(
      this.payload.account,
      this.payload.sum,
      this.payload.email,
      sbp
    );

    if (link.url) {
      // window.open(link.url, '_blank').focus();
      window.location.href = link.url;
    }

    this.setCheckLsState();
  }
}
