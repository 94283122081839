import FormRules from "@/forms/FormRules";
import AuthService from "../services/AuthService";

export default class LoginForm extends FormRules {
  valid = true;
  name = "LoginForm";

  seconds = 0;
  type = "";

  payload = {
    login: "",
    code: "",
    confirmPD: false,
  };

  fields = {
    contact: {
      label: "E-mail или телефон",
      type: "text",
      rules: [this.required("Заполните данное поле"), this.validLogin()],
      required: true,
      hidden: false,
      onEnter: () => {
        if (this.payload.confirmPD) {
          AuthService.getVerificationCode(this).then(() => {});
        }
      },
      // onEnter: () => AuthService.getVerificationCode(this),
    },
    code: {
      label: "Проверочный код",
      type: "text",
      rules: [this.required("Введите код")],
      required: true,
      hidden: true,
      onEnter: () => {
        if (this.payload.confirmPD) {
          AuthService.loginUser(this.payload.code, this.payload.confirmPD).then(
            () => {}
          );
        }
      },
      // onEnter: () => AuthService.loginUser(this.payload.code, this.payload.confirmPD),
    },
    confirmPD: {
      label: "Даю согласие на обработку ООО «КРКЦ» моих персональных данных",
      type: "checkbox",
      rules: [this.required("Подтвердите согласие")],
    },
  };

  buttons = {
    getCode: {
      label: "Получить код",
      action: () => AuthService.getVerificationCode(this),
      type: "submit",
      block: true,
      hidden: false,
      useLoading: true,
      disabled: !this.payload.confirmPD,
    },
    sendCode: {
      label: "Войти",
      action: () =>
        AuthService.loginUser(this.payload.code, this.payload.confirmPD),
      type: "submit",
      block: true,
      hidden: true,
      useLoading: true,
      disabled: !this.payload.confirmPD,
    },
  };

  setSendCodeState(seconds, type) {
    this.seconds = seconds;
    this.type = type;
    this.fields.contact.hidden = true;
    this.fields.code.hidden = false;
    this.buttons.getCode.hidden = true;
    this.buttons.sendCode.hidden = false;
  }

  setGetCodeState() {
    this.fields.contact.hidden = false;
    this.fields.code.hidden = true;
    this.buttons.getCode.hidden = false;
    this.buttons.sendCode.hidden = true;
  }
}
